import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { Parallax } from 'react-scroll-parallax'
import ContentLayout from '../misc/ContentLayout/ContentLayout'
import Logo from '../misc/Logo/Logo'
import MenuItem, { MENU_TYPES } from '../misc/MenuItem/MenuItem'

import { NAV_IDS } from '../misc/NavbarDesktop/NavbarDesktop'
import TitleDescription from '../misc/TitleDescription/TitleDescription'
import * as styles from './Menu.module.scss'

const Menu = ({ data }) => {
  return (
    <ContentLayout>
      <div className={styles.container} id={NAV_IDS.MENU}>
        <TitleDescription
          title="MENU"
          description="Whether it is lunch or dinner, each recipe has been developed to show fusion Vietnamese cuisine at its best. We are constantly evolving our menu and doing our part to spice up the local Asian restaurant scene in Helsinki. If you have special dietary needs, check our menu for details and reach out to our staff with any questions."
          description2="Olipa kyseessä lounas tai illallinen, jokainen reseptimme on kehitetty esittelemään parhaat puolet vietnamilaisesta fuusio ruuasta, jossa raikkaat yrtit ja tuliset chilit sekoittuvat aistikkaaksi elämykseksi. Kehitämme jatkuvasti reseptejämme, jotta voimme tuoda oman mausteemme piristämään paikallista aasialaista ravintolakulttuuria Helsingissä."
          blinkText="New!"
          dash={data.line.childImageSharp.gatsbyImageData}
        />
      </div>
      <Parallax
        translateX={['-50px', '100px']}
        translateY={['80px', '-80px']}
        tagOuter="figure"
        style={{ height: 0 }}
      >
        <div className={styles.parallax}>
          <GatsbyImage
            image={data.menuParallax.childImageSharp.gatsbyImageData}
          />
        </div>
      </Parallax>
      <div className={styles.menuWrapper}>
        <div className={styles.logoWrapper}>
          <Logo secondary className={styles.logo} />
          <GatsbyImage
            image={data.logo2Bottom.childImageSharp.gatsbyImageData}
          />
        </div>
        <div className={styles.menuItems}>
          <Link to="/menu/#lunch" className={styles.link}>
            <MenuItem menuType={MENU_TYPES.LUNCH} />
          </Link>
          <Link to="/menu/#dinner" className={styles.link}>
            <MenuItem menuType={MENU_TYPES.CLASSICS} />
          </Link>
          <Link to="/menu/#starter" className={styles.link}>
            <MenuItem menuType={MENU_TYPES.TAPAS} />
          </Link>
          <Link to="/menu/#drinks" className={styles.link}>
            <MenuItem menuType={MENU_TYPES.DRINKS} />
          </Link>
        </div>
      </div>
      <GatsbyImage
        image={data.separator.childImageSharp.gatsbyImageData}
        className={styles.separator}
      />
    </ContentLayout>
  )
}

export default Menu
