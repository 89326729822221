import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'

// import Home from "../components/Home/Home"
import { ParallaxProvider } from 'react-scroll-parallax'
import About from '../components/About/About'
import Footer from '../components/Footer/Footer'
import FrontPage from '../components/FrontPage/FrontPage'
import Intro from '../components/Intro/Intro'
import Layout from '../components/Layout/Layout'
import Menu from '../components/Menu/Menu'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      video: file(relativePath: { eq: "video.png" }) {
        childImageSharp {
          gatsbyImageData(width: 700, quality: 30, layout: CONSTRAINED)
        }
      }
      picture: file(relativePath: { eq: "intro_picture.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 700, quality: 30, layout: CONSTRAINED)
        }
      }
      footerImg: file(relativePath: { eq: "footer.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 30, layout: FULL_WIDTH)
        }
      }
      line: file(relativePath: { eq: "intro_dash.png" }) {
        childImageSharp {
          gatsbyImageData(width: 155, quality: 30, layout: FIXED)
        }
      }
      socialInsta: file(relativePath: { eq: "social_instagram.png" }) {
        childImageSharp {
          gatsbyImageData(width: 65, quality: 30, layout: FIXED)
        }
      }
      socialFacebook: file(relativePath: { eq: "social_facebook.png" }) {
        childImageSharp {
          gatsbyImageData(width: 65, quality: 30, layout: FIXED)
        }
      }
      separator: file(relativePath: { eq: "separator.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 30, layout: FULL_WIDTH)
        }
      }
      logo1Bottom: file(relativePath: { eq: "logo_bottom.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100, quality: 30, layout: FIXED)
        }
      }
      logo2Bottom: file(relativePath: { eq: "logo_secondary_bottom.png" }) {
        childImageSharp {
          gatsbyImageData(width: 36, quality: 30, layout: FIXED)
        }
      }
      branch: file(relativePath: { eq: "branch.png" }) {
        childImageSharp {
          gatsbyImageData(width: 50, quality: 30, layout: FIXED)
        }
      }
      introParallax: file(relativePath: { eq: "intro_parallax.png" }) {
        childImageSharp {
          gatsbyImageData(width: 700, quality: 30, layout: CONSTRAINED)
        }
      }
      aboutParallax: file(relativePath: { eq: "about_parallax.png" }) {
        childImageSharp {
          gatsbyImageData(width: 250, quality: 30, layout: CONSTRAINED)
        }
      }
      menuParallax: file(relativePath: { eq: "menu_parallax.png" }) {
        childImageSharp {
          gatsbyImageData(width: 580, quality: 30, layout: CONSTRAINED)
        }
      }
      footerParallax: file(relativePath: { eq: "footer_parallax.png" }) {
        childImageSharp {
          gatsbyImageData(width: 300, quality: 30, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <ParallaxProvider>
      <Layout title="Be My Guest | Vietnamilainen Ravintola | Jätkäsaari Helsinki">
        <FrontPage />
        <Intro data={data} />
        <About data={data} />
        <Menu data={data} />
        {/* <Instagram /> */}
        <Footer />
      </Layout>
    </ParallaxProvider>
  )
}

export default IndexPage
