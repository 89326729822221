import classnames from 'classnames/bind'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'

import Video from '../../images/video.mp4'
import ButtonLink from '../misc/ButtonLink/ButtonLink'
import Logo from '../misc/Logo/Logo'
import NavbarContainer from '../misc/NavbarContainer/NavbarContainer'
import * as styles from './FrontPage.module.scss'

let cx = classnames.bind(styles)

const FrontPage = () => {
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((index) => index + 1)

      return () => {
        clearInterval(interval)
      }
    }, 8000)
  }, [])

  const data = useStaticQuery(graphql`
    query DataQuery {
      shape: file(relativePath: { eq: "intro_shape.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600, quality: 30, layout: CONSTRAINED)
        }
      }
      shape2: file(relativePath: { eq: "shape2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 25, quality: 30, layout: FIXED)
        }
      }
      branch2: file(relativePath: { eq: "branch2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 28, quality: 30, layout: FIXED)
        }
      }
      shape3: file(relativePath: { eq: "shape3.png" }) {
        childImageSharp {
          gatsbyImageData(width: 700, quality: 30, layout: CONSTRAINED)
        }
      }
      carousel1: file(relativePath: { eq: "carousel-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 50, layout: FULL_WIDTH)
        }
      }
      carousel2: file(relativePath: { eq: "carousel-2.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 50, layout: FULL_WIDTH)
        }
      }
      carousel3: file(relativePath: { eq: "carousel-3.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 50, layout: FULL_WIDTH)
        }
      }
      carousel4: file(relativePath: { eq: "carousel-4.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 50, layout: FULL_WIDTH)
        }
      }
      carousel5: file(relativePath: { eq: "carousel-5.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 50, layout: FULL_WIDTH)
        }
      }
    }
  `)

  const carouselImages = [
    data.carousel3.childImageSharp.gatsbyImageData,
    data.carousel2.childImageSharp.gatsbyImageData,
    data.carousel1.childImageSharp.gatsbyImageData,
    data.carousel4.childImageSharp.gatsbyImageData,
    data.carousel5.childImageSharp.gatsbyImageData,
  ]

  const currentIndex = index % carouselImages.length

  return (
    <div className={styles.container}>
      <NavbarContainer />
      <div className={styles.carousel}>
        <span className={styles.backgroundOverlay} />
        <GatsbyImage
          image={carouselImages[currentIndex]}
          alt="Best banh mi in helsinki"
          style={{
            width: '100%',
          }}
          className={styles.background}
        />
        <div className={styles.logoContainer}>
          <Logo hasBottom={false} />
          <div className={styles.indicatorWrapper}>
            {carouselImages.map((img, i) => (
              <div
                key={i}
                className={cx(styles.indicator, {
                  indicatorActive: currentIndex === i,
                })}
              />
            ))}
          </div>
        </div>
        <div className={styles.sloganContainer}>
          <div className={styles.slogan}>
            <span>
              Soft, crunchy, sweet, spicy - <br />a party of contrasts.
            </span>
            <div className={styles.btnContainer}>
              <ButtonLink linkTo="/menu" text="MENU" />
              <ButtonLink
                linkTo="/catering"
                text="CATERING"
                customClass={styles.hideOnDesktop}
              />
              <ButtonLink
                linkTo="/reserve/"
                text="Reserve"
                customClass={styles.hideOnDesktop}
              />
            </div>
            <div className={styles.openingHours}>
              <div>Opening hours:</div>
              <div>Mon-Fri 11-21</div>
              <div>Sat 12-21</div>
              <div>Sun: Closed</div>
            </div>
            <div className={styles.exceptionalHours}>
              <div>Kitchen closes 30 minutes before closing time.</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.movie}>
        <video muted controls={false} autoPlay loop playsInline>
          <source src={Video} type="video/mp4" />
        </video>
        <div className={styles.shape3}>
          <GatsbyImage
            alt=""
            image={data.shape3.childImageSharp.gatsbyImageData}
          />
        </div>
        <div className={styles.shape2}>
          <GatsbyImage
            alt=""
            image={data.shape2.childImageSharp.gatsbyImageData}
          />
        </div>
      </div>
    </div>
  )
}

export default FrontPage
