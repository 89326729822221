import { GatsbyImage } from 'gatsby-plugin-image'

import { OutboundLink } from 'gatsby-plugin-google-analytics'
import React from 'react'
import { Parallax } from 'react-scroll-parallax'
import ContentLayout from '../misc/ContentLayout/ContentLayout'
import Logo from '../misc/Logo/Logo'
import TitleDescription from '../misc/TitleDescription/TitleDescription'
import * as styles from './Intro.module.scss'

const Intro = ({ data }) => {
  return (
    <ContentLayout>
      <div className={styles.container}>
        <TitleDescription
          title={'BE MY GUEST'}
          description={`Located in the Ruoholahti district of Helsinki, Be My Guest serves authentic Vietnamese food since 2019. We bring you healthy, fresh and exciting lunch and dinner in an ambience resembling a cosy living room. From noodles, dumplings and Pho to funky drinks, let's reimagine the subtle taste of Asian food in Helsinki!`}
          description2={`Olemme vietnamilainen ravintola ja sijaintimme on Ruoholahti, Helsinki. Tarjoamme aitoa vietnamilaista ruokaa - terveellistä, tuoretta ja aistikasta, joka avaa aasialaisen ruuan mahdollisuuksien maailman uudella herkullisella tavalla; nuudelli, wings, dumpling tai Pho saavat uuden ulottuvuuden. Maistuvalta juomalistalta löytyy myös virkistäviä seuralaisia ruuallesi.`}
          dash={data.line.childImageSharp.gatsbyImageData}
        />
        <GatsbyImage
          image={data.picture.childImageSharp.gatsbyImageData}
          className={styles.imageWrapper}
        />
      </div>
      <Parallax
        translateX={['50px', '-50px']}
        translateY={['80px', '-80px']}
        tagOuter="figure"
      >
        <div className={styles.parallax}>
          <GatsbyImage
            image={data.introParallax.childImageSharp.gatsbyImageData}
          />
        </div>
      </Parallax>
      <div className={styles.mediaLinks}>
        <div className={styles.logoWrapper}>
          <Logo secondary className={styles.logo} />
          <GatsbyImage
            image={data.logo2Bottom.childImageSharp.gatsbyImageData}
          />
        </div>
        <GatsbyImage
          image={data.separator.childImageSharp.gatsbyImageData}
          className={styles.separator}
          style={{ display: 'inherit' }}
        />
        <div className={styles.socialBtn}>
          <span className={styles.media}>@bemyguest.fi</span>
          <OutboundLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/bemyguest.fi/"
            className={styles.socialIcon}
          >
            <GatsbyImage
              image={data.socialInsta.childImageSharp.gatsbyImageData}
            />
          </OutboundLink>
          <OutboundLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/bemyguest.fi/"
            className={styles.socialIcon}
          >
            <GatsbyImage
              image={data.socialFacebook.childImageSharp.gatsbyImageData}
            />
          </OutboundLink>
        </div>
      </div>
    </ContentLayout>
  )
}

export default Intro
