// extracted by mini-css-extract-plugin
export var container = "Intro-module--container--kx8x+";
export var imageWrapper = "Intro-module--imageWrapper--ATeKB";
export var logo = "Intro-module--logo--vWRAx";
export var logoWrapper = "Intro-module--logoWrapper--zj0uV";
export var media = "Intro-module--media--k5VFC";
export var mediaLinks = "Intro-module--mediaLinks--SowUv";
export var parallax = "Intro-module--parallax--Wtgs8";
export var separator = "Intro-module--separator--6Y6as";
export var socialBtn = "Intro-module--socialBtn--lT-Ak";
export var socialIcon = "Intro-module--socialIcon--FQ6nO";