import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { Parallax } from 'react-scroll-parallax'
import ContentLayout from '../misc/ContentLayout/ContentLayout'
import Logo from '../misc/Logo/Logo'
import { NAV_IDS } from '../misc/NavbarDesktop/NavbarDesktop'
import TitleDescription from '../misc/TitleDescription/TitleDescription'
import * as styles from './About.module.scss'

const About = ({ data }) => {
  return (
    <ContentLayout>
      <div className={styles.container} id={NAV_IDS.ABOUT}>
        <video
          controls={false}
          autoPlay
          loop
          muted
          playsInline
          className={styles.imageWrapper}
        >
          {/* <source src={Video} type="video/mp4" /> */}
        </video>
        <TitleDescription
          title={'Who are we?'}
          description={`Starting as a dream by 6 international friends, Be My Guest quickly turned from an idea of “how great it would be to start a restaurant” – into a true passion for bringing people together with a shared love of good food in Finland. We want you to feel at home with us. Come by, say hi, and enjoy a laid-back atmosphere and fresh food.`}
          description2={`Alussa 6:lla kaverilla eri kansallisuuksista oli sama unelma: ”miten mahtavaa olisi avata ravintola”. Rakkaus ja intohimo tuoda ihmiset yhteen hyvän ruuan äärelle siivittivät nopeasti ajatukset tekoihin, ja Be My Guest oli syntynyt. Haluamme että tunnet olosi kotoisaksi meidän kanssamme. Tule käymään, sano hei, ja nauti rennosta tunnelmasta ja tuoreesta ruuasta.`}
          dash={data.line.childImageSharp.gatsbyImageData}
          left={false}
        />
      </div>
      <Parallax
        translateY={['80px', '-80px']}
        translateX={['-50px', '80px']}
        tagOuter="figure"
        style={{ height: 0 }}
      >
        <div className={styles.parallax}>
          <GatsbyImage
            alt=""
            image={data.aboutParallax.childImageSharp.gatsbyImageData}
          />
        </div>
      </Parallax>
      <div className={styles.mediaLinks}>
        <GatsbyImage
          alt=""
          image={data.separator.childImageSharp.gatsbyImageData}
          className={styles.separator}
        />
        <div className={styles.logoWrapper}>
          <Logo className={styles.logo} />
          <GatsbyImage
            alt=""
            image={data.logo1Bottom.childImageSharp.gatsbyImageData}
          />
        </div>
      </div>
    </ContentLayout>
  )
}

export default About
