// extracted by mini-css-extract-plugin
export var background = "FrontPage-module--background--G4RJY";
export var backgroundOverlay = "FrontPage-module--backgroundOverlay--61L3F";
export var btnContainer = "FrontPage-module--btnContainer--Zu8-A";
export var campaignLink = "FrontPage-module--campaignLink--DTRWB";
export var carousel = "FrontPage-module--carousel--+j39I";
export var container = "FrontPage-module--container--fzux0";
export var desktopNavbar = "FrontPage-module--desktopNavbar--9xK2m";
export var exceptionalHours = "FrontPage-module--exceptionalHours--+F0VB";
export var hideOnDesktop = "FrontPage-module--hideOnDesktop--2rV6G";
export var indicator = "FrontPage-module--indicator--pdCGL";
export var indicatorActive = "FrontPage-module--indicatorActive--qNMVe";
export var indicatorWrapper = "FrontPage-module--indicatorWrapper--dK94B";
export var link = "FrontPage-module--link--UK81Q";
export var logoContainer = "FrontPage-module--logoContainer--Iih9p";
export var menuBtn = "FrontPage-module--menuBtn--Kx1UT";
export var mobileNavbar = "FrontPage-module--mobileNavbar--Sbcgp";
export var movie = "FrontPage-module--movie--yQV9-";
export var navContainer = "FrontPage-module--navContainer--3kdGU";
export var openingHours = "FrontPage-module--openingHours--Q+AUP";
export var shape = "FrontPage-module--shape--KzWlf";
export var shape2 = "FrontPage-module--shape2--qzq+T";
export var shape3 = "FrontPage-module--shape3--uMp7-";
export var slogan = "FrontPage-module--slogan--wy7yC";
export var sloganContainer = "FrontPage-module--sloganContainer--EoJvT";